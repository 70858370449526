import { Component } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Store } from "@ngrx/store";
import { initialState, State } from './models/patient';
import { ConfigService } from './services/config.service';
import * as fromRoot from './store/reducers';
import { SiteLinks } from './models/config';
import { ThemeService } from './services/theme.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title: string;
  public patient: State = initialState;
  public loggedIn: boolean = false;
  public clinicName: string;
  public ready = false;
  public siteConfig: SiteLinks;

  constructor(public router: Router, 
    private store: Store<fromRoot.State>, 
    private config: ConfigService, 
    private themeService: ThemeService
  ) { 
    this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {

          gtag('config', 'G-47KFM3K2CK', {'page_path': event.urlAfterRedirects});

         // if ( this.router.url.split("/")[1] !== "clinic" && !this.config.getJWT() ) this.router.navigate(["unknown"]);
        }
      }
    );

    this.store.select(fromRoot.isPatientLoggedIn).subscribe((loggedIn: boolean) => this.loggedIn = loggedIn );
    this.store.select(fromRoot.getPatientDetails).subscribe((patient: State) => this.patient = patient );

    this.config.getStoredClinicDetails().subscribe((clinic: any) => {
      
      this.clinicName = clinic.name;
      console.log("[config] got", this.clinicName);
    })

    this.config.getSiteConfig().subscribe(res=>{
      this.siteConfig = res;
      this.themeService.setTheme(this.siteConfig.theme);
    })
/*
    this.staff.init().subscribe(res=>{
      this.ready = true;
    });
  */
  }

  public loginRequired(): boolean {
    return ( this.withoutQueries(this.router.url) === "/details" );
  }

  public withoutQueries(url: string): string {
    return ( url.indexOf("?") !== -1 ) ? url.substring(0, url.indexOf("?")) : url;
  }
 

}
