
<button mat-button (click)="home()"><mat-icon>arrow_back</mat-icon>
    Back</button>

<div class="inner-container">
    <div class="call-to-action">New Patient Registration</div>
    <div class="alert alert-warning" *ngIf="duplicateWarning">
        Some of the details you have provided apprear to match with a patient we already have on file and we are unable
        to register you as a new patient.<br />
        You could either try to <a routerLink="/login">log in</a> using your email or contact the clinic on <a
            href="tel: {{clinic.phone}}">{{clinic.phone}}</a>
    </div>

    <div class="row forms">

        <form #registrationForm="ngForm">
            <div class="col-lg-12 form">

                <mat-form-field class='w-100'>
                    <mat-label>First Name</mat-label>
                    <input matInput placeholder="First Name" [formControl]="FirstName" required>
                    <mat-error *ngIf="FirstName.invalid">{{ getErrorMessage("FirstName") }}</mat-error>
                </mat-form-field>

                <mat-form-field class='w-100'>
                    <mat-label>Last Name</mat-label>
                    <input matInput placeholder="Last Name" [formControl]="LastName" required>
                    <mat-error *ngIf="LastName.invalid">{{ getErrorMessage("LastName") }}</mat-error>
                </mat-form-field>

                <mat-form-field class="date-input"  class='w-100'>
                    <mat-label>Date of Birth</mat-label>
                    <input matInput [formControl]="dob" required placeholder="Date of Birth">
                    <mat-hint>DD/MM/YYYY</mat-hint>
                    <mat-error *ngIf="dob.invalid">Please enter a date of birth in form at DD/MM/YYYY</mat-error>
                </mat-form-field>


                <mat-form-field>
                    <mat-label>Email Address</mat-label>
                    <input matInput type="email" placeholder="Email Address" [formControl]="EMail" required>
                    <mat-error *ngIf="EMail.invalid">{{ getErrorMessage("EMail") }}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Mobile Phone</mat-label>
                    <input matInput placeholder="Mobile Phone" [formControl]="MobilePhone" required>
                    <mat-error *ngIf="MobilePhone.invalid">{{ getErrorMessage("MobilePhone") }}</mat-error>
                </mat-form-field>

                <div class="form-group">
                    <mat-checkbox type='checkbox' class='checkbox' [formControl]="mailshot">
                        Email appointment reminders
                    </mat-checkbox>
                </div>
                <div class="form-group">
                    <mat-checkbox type='checkbox' class='checkbox' [formControl]="sms">
                        Text appointment reminders
                    </mat-checkbox>
                </div>
                <div class="form-group">
                    <mat-checkbox type='checkbox' class='checkbox' [formControl]="ConsentMarketing">
                        Marketing and offers
                    </mat-checkbox>
                </div>

                <div class="d-flex justify-content-end my-3">
                  
                    <div>
                        <button mat-raised-button color="primary" (click)="send()">Next
                        </button>
                    </div>
                </div>

            </div>
        </form>

    </div>

</div>