import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, iif } from 'rxjs';
import * as fromRoot from '../store/reducers';

@Injectable({
    providedIn: 'root'
})
export class ClinicSetGuard {
    loggedIn: boolean

    constructor(private config: ConfigService,
        private store: Store<fromRoot.State>,
        private router: Router) { }

    canActivate() {
        const cookie = document.cookie;
        const clinicFromCookie = /clinic=(.*);/.exec(cookie);

        //        console.log("[guard] can activate", cookie, /clinic=(.*);/.exec(cookie));


        return this.config.getClinicName().pipe(map(res => {
            if (res) { //we have a clinic, check if we are logged-in.
                this.store.select(fromRoot.isPatientLoggedIn).subscribe((loggedIn: boolean) => {
                    if (!loggedIn) {
                        console.log("[guard] clinic loads config");
                        this.config.loadConfig(res);
                    }
                    return true;
                });
                return true;
            } else if (clinicFromCookie && clinicFromCookie[1]) {
                this.config.setClinicName(clinicFromCookie[1]);
                return true;
            } else {
                console.log("[guard] idk this clinic");
                this.router.navigate(['/unknown']);
                return false;
            }
        }));
    }


}
