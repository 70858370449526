import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-initialize',
  templateUrl: './initialize.component.html',
  styleUrls: ['./initialize.component.scss']
})
export class InitializeComponent implements OnInit, OnDestroy {
  private params$: Subscription;
  private clinicName$: Subscription;
  private loading = false;
  private currentClinic: string | undefined
  private storedClinic: string | undefined
  readonly CLINICNAME: string = "clinicName";
  private egressRoute = ['/'];

  constructor( 
    private route: ActivatedRoute, 
    private config: ConfigService,
    private router: Router ) {
      this.clinicName$ = this.config.getClinicName().subscribe(res=>{
        this.storedClinic = res;
        if (res==this.currentClinic){
          console.log("hi [init] matched go with res=", res, this.currentClinic);
          this.router.navigate(this.egressRoute); //changed
        }
        console.log("hi [init] NOT matched with res=", res, this.currentClinic);

      })
     }

  ngOnInit(): void {
    console.log("hi [init] here");
    this.loading = false;
    this.params$ = this.route.params.subscribe(
      ( params: Params ) => {
        if (params['appointment']){
          this.egressRoute = ['payment', params['appointment']];
        }
        if ( params[this.CLINICNAME] && !this.loading) {
          this.loading = true;
          this.route.queryParams.subscribe((queryParams: Params) => {
            console.log("[clinic] loading config for ", params[this.CLINICNAME], queryParams);
            this.currentClinic = params[this.CLINICNAME];
            this.config.loadConfig( params[this.CLINICNAME], queryParams['patientIdx'] );
            if (this.storedClinic == this.currentClinic){
              if (queryParams['ical']){
                console.log("go ical");
                this.router.navigate(['ical', {ical: queryParams['ical']}]);
                return;
              }
              this.router.navigate(this.egressRoute); //not changed
            }
          })
        } 
      }, 
      err => {
        console.log("init error occured", err)
        this.router.navigate(['unknown']);
      }
    );

  }

  ngOnDestroy(){
    if (this.params$) { this.params$.unsubscribe(); }
  }
}
