<div class="background"></div>
<app-header></app-header>
<header><b>{{clinicName}}</b> at <b>{{siteConfig.organisationName}}'s</b> Online Booking</header>
<app-loading></app-loading>

        <div class="container" >
            <router-outlet></router-outlet>
        </div>

<app-footer></app-footer>

<app-snackbar></app-snackbar>